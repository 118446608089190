import React, { useState } from 'react';
import './FileUploader.css';

function FileUploader({ onFileUpload }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFiles = (files) => {
    const newFiles = [];
    const duplicateFiles = [];

    files.forEach(file => {
      if (uploadedFiles.some(uploadedFile => uploadedFile.name === file.name)) {
        duplicateFiles.push(file.name);
      } else {
        newFiles.push(file);
      }
    });

    if (duplicateFiles.length > 0) {
      setErrorMessage(`Duplicate files: ${duplicateFiles.join(', ')} were not uploaded.`);
    } else {
      setErrorMessage('');
    }

    if (newFiles.length > 0) {
      setUploadedFiles([...uploadedFiles, ...newFiles]);
      onFileUpload(newFiles);
    }
  };

  const handleFilesInput = (event) => {
    const files = Array.from(event.target.files);
    handleFiles(files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleFiles(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div
        className="drop-area"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <p>Drag & Drop your PDF files here, or click to select files</p>
        <input
          type="file"
          accept="application/pdf"
          multiple
          onChange={handleFilesInput}
          style={{ display: 'none' }}
          id="file-input"
        />
        <label htmlFor="file-input">
          Select Files
        </label>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default FileUploader;
