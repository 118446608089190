import { PDFDocument } from 'pdf-lib';

export async function combinePdfs(pages) {
  const combinedPdf = await PDFDocument.create();

  for (const { file, pageIndex } of pages) {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const [copiedPage] = await combinedPdf.copyPages(pdfDoc, [pageIndex]);
    combinedPdf.addPage(copiedPage);
  }

  const pdfBytes = await combinedPdf.save();
  return pdfBytes;
}
