import React from 'react';
import './BackgroundSlots.css';

function BackgroundSlots({ slotCount }) {
  const slots = Array.from({ length: slotCount }, (_, index) => (
    <div key={index} className="background-slot">
      <div className="slot-number">{index + 1}</div>
    </div>
  ));

  return <div className="background-slots-container">{slots}</div>;
}

export default BackgroundSlots;
