import React, { useState, useEffect } from 'react';
import GridLayout from 'react-grid-layout';
import { PDFDocument } from 'pdf-lib'; // Add this import
import PDFSinglePage from './PDFSinglePage';
import PDFModal from './PDFModal';
import BackgroundSlots from './BackgroundSlots';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './PDFGrid.css';

function PDFGrid({ files, setOrderedPages }) {
  const [layout, setLayout] = useState([]);
  const [modalInfo, setModalInfo] = useState(null);
  const [orderedPages, setLocalOrderedPages] = useState([]);
  const [disableAnimation, setDisableAnimation] = useState(false);

  useEffect(() => {
    const loadPages = async () => {
      const allPages = [];
      for (const file of files) {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const pageCount = pdfDoc.getPageCount();
        for (let i = 0; i < pageCount; i++) {
          const uniqueId = `${file.name}-${i}`;
          allPages.push({ file, pageIndex: i, id: uniqueId });
        }
      }

      const newLayout = allPages.map((page, index) => ({
        i: page.id,
        x: index % 4,
        y: Math.floor(index / 4),
        w: 1,
        h: 1,
      }));

      setLocalOrderedPages(allPages);
      setOrderedPages(allPages);
      setLayout(newLayout);
    };

    loadPages();
  }, [files, setOrderedPages]);

  const onLayoutChange = (newLayout) => {
    const newOrderedPages = newLayout
      .sort((a, b) => (a.y * 4 + a.x) - (b.y * 4 + b.x))
      .map(item => orderedPages.find(page => page.id === item.i));
    setLayout(newLayout);
    setLocalOrderedPages(newOrderedPages);
    setOrderedPages(newOrderedPages);
  };

  const onDragStop = (layout) => {
    const newOrderedPages = layout
      .sort((a, b) => (a.y * 4 + a.x) - (b.y * 4 + b.x))
      .map(item => orderedPages.find(page => page.id === item.i));
    setDisableAnimation(true);
    setLayout(layout);
    setLocalOrderedPages(newOrderedPages);
    setOrderedPages(newOrderedPages);

    setTimeout(() => {
      setDisableAnimation(false);
    }, 100);
  };

  const openModal = (file, pageIndex) => {
    setModalInfo({ file, pageIndex });
  };

  const closeModal = () => {
    setModalInfo(null);
  };

  const handleMouseDown = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="pdf-grid-wrapper">
        <BackgroundSlots slotCount={orderedPages.length} />
        <div className="pdf-grid-container">
          <GridLayout
            className={`layout ${disableAnimation ? 'no-animation' : ''}`}
            layout={layout}
            cols={4}
            rowHeight={300}
            width={1200}
            onLayoutChange={onLayoutChange}
            onDragStop={onDragStop}
            isResizable={false}
            compactType="horizontal"
            preventCollision={false}
            useCSSTransforms={true}
            transitionDuration={500}
            droppingItem={{ w: 1, h: 1 }}
          >
            {orderedPages.map((page, index) => (
              <div key={page.id} data-grid={layout[index]} className={`pdf-page-container ${disableAnimation ? 'no-animation' : ''}`}>
                <PDFSinglePage
                  file={page.file}
                  pageIndex={page.pageIndex}
                  onMagnify={() => openModal(page.file, page.pageIndex)}
                  onMouseDown={handleMouseDown}
                />
              </div>
            ))}
          </GridLayout>
          {modalInfo && (
            <PDFModal
              file={modalInfo.file}
              pageIndex={modalInfo.pageIndex}
              onClose={closeModal}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default PDFGrid;
