import React, { useState, useEffect, useCallback } from 'react';
import FileUploader from './components/FileUploader';
import PDFGrid from './components/PDFGrid';
import Navbar from './components/Navbar';
import { combinePdfs } from './utils/combinePdfs';
import './App.css';

function App() {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [orderedPages, setOrderedPages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);

  const handleFileUpload = useCallback((newFiles) => {
    const allPages = [...orderedPages];
    const updatedFiles = [...pdfFiles];

    newFiles.forEach(file => {
      const existingFile = updatedFiles.find(f => f.name === file.name);
      if (!existingFile) {
        updatedFiles.push(file);
      }

      const fileIndex = updatedFiles.indexOf(file);
      allPages.push({ file, pageIndex: 0, fileIndex });
    });

    setPdfFiles(updatedFiles);
    setOrderedPages(allPages);
  }, [orderedPages, pdfFiles]);

  const handleCombinePdfs = async () => {
    const pdfBytes = await combinePdfs(orderedPages);
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'combined.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    setDragCounter((prevCounter) => prevCounter + 1);
    if (dragCounter === 0) {
      setIsDragging(true);
    }
  }, [dragCounter]);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragCounter((prevCounter) => {
      const newCounter = prevCounter - 1;
      if (newCounter === 0) {
        setIsDragging(false);
      }
      return newCounter;
    });
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    setDragCounter(0);
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files);
    handleFileUpload(files);
  }, [handleFileUpload]);

  useEffect(() => {
    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop]);

  return (
    <div className="App dark-mode">
      {isDragging && (
        <div className="drop-overlay">
          <p>Drop your PDF files here</p>
        </div>
      )}
      <div className={`content ${isDragging ? 'blurred' : ''}`}>
        <Navbar onCombine={handleCombinePdfs} />
        <FileUploader onFileUpload={handleFileUpload} />
        {pdfFiles.length > 0 && <PDFGrid files={pdfFiles} orderedPages={orderedPages} setOrderedPages={setOrderedPages} />}
      </div>
    </div>
  );
}

export default App;
