import React, { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import './PDFSinglePage.css'; // Import CSS for styling

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

function PDFSinglePage({ file, pageIndex, onMagnify, onMouseDown }) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const renderPage = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const pdfDoc = await pdfjsLib.getDocument(arrayBuffer).promise;
      const page = await pdfDoc.getPage(pageIndex + 1);
      const viewport = page.getViewport({ scale: 1 });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;

      const scaleFactor = Math.min(200 / canvas.width, 300 / canvas.height);
      const scaledWidth = canvas.width * scaleFactor;
      const scaledHeight = canvas.height * scaleFactor;

      const scaledCanvas = document.createElement('canvas');
      scaledCanvas.width = scaledWidth;
      scaledCanvas.height = scaledHeight;
      const scaledContext = scaledCanvas.getContext('2d');
      scaledContext.drawImage(canvas, 0, 0, scaledWidth, scaledHeight);

      setImageUrl(scaledCanvas.toDataURL());
    };

    renderPage();
  }, [file, pageIndex]);

  return (
    <div className="pdf-page">
      {imageUrl && <img src={imageUrl} alt={`Page ${pageIndex + 1}`} />}
      <button className="magnify-button" onMouseDown={onMouseDown} onClick={onMagnify}>
        🔍
      </button>
    </div>
  );
}

export default PDFSinglePage;
