import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from './pdf_manipulation_icon1.png'; // Adjust the path as necessary

function Navbar({ onCombine }) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClear = () => {
    window.location.reload(); // Refresh the page
  };

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-brand">
        <img src={logo} alt="PDF Manipulation Logo" className="navbar-logo" />
        Split-N-Merge PDF
      </div>
      <div>
        <button className="navbar-button combine-button" onClick={onCombine}>Combine PDFs</button>
        <button className="navbar-button clear-button" onClick={handleClear}>Clear</button>
      </div>
    </nav>
  );
}

export default Navbar;
